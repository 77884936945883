<template>
  <v-dialog
    v-model="visible"
    max-width="500px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card :loading="importLoading">
      <v-card-title>Import {{ title }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="12"
            >
              Are you sure you want to import this data? (this will
              override old data)
              <!-- Previous Versions: -->
            </v-col>
            <v-col class="d-flex justify-center">
              <v-progress-circular
                :rotate="360"
                :size="100"
                :width="15"
                :value="percentageValue"
                color="primary"
              >
                {{ percentageValue }}%
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red"
          text
          :disabled="importLoading"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="importLoading"
          @click="confirm"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImportDialog",
  props: {
    title: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      importLoading: false,
      percentageValue: 0
    }
  },
  methods: {
    close() {
      if(!this.importLoading) {
        this.$emit('cancel')
      }
    },
    confirm() {
      this.$emit('click')
      this.importLoading = true
    }
  }
}
</script>

<style scoped>

</style>
