<style scoped>
</style>
<template>
  <div>
    <v-card
      class="mt-4 py-4 px-2"
      elevation="0"
    >
      <v-card-title>Mappings</v-card-title>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <v-combobox
        v-model="selectedTable"
        :items="projectTables"
        item-text="name"
        item-value="id"
        label="Mapping table"
      />
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="column in tableColumns"
              :key="column.name"
            >
              <td>{{ column.name }}</td>
              <td>
                <v-combobox
                  dense
                  :disabled="isLoading"
                  :items="modelProperties.map((p) => p.name)"
                  @change="(e) => mapValueToColumn(e, column.name)"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="d-flex mt-4">
        <v-spacer />
        <v-btn
          :disabled="isLoading"
          color="primary"
          elevation="1"
          @click="openModelImportDialog"
        >
          Import
        </v-btn>
      </div>
      <ImportDialog
        ref="importDialog"
        :title="model.objectKey"
        :visible="displayImportDialog"
        @click="save"
        @cancel="closeImportDialog"
      />
    </v-card>
  </div>
</template>

<script>
import ImportDialog from "./ImportDialog";
import {mapGetters} from "vuex";
import {convertToURN} from "@/components/Modules/Model-Manager/utils/ModelManager+utils";
import {getColumns} from "@/services/api/column.api";

export default {
  name: "Mapper",
  components: {
    ImportDialog
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapping: [],
      displayImportDialog: false,
      selectedTable: undefined,

      tableColumns: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['project', 'projectTables', 'modelProperties'])
  },
  watch: {
    model: {
      immediate: true,
      handler(newVal, oldVal) {
        this.getModelProperties()
      }
    },
    selectedTable() {
      this.isLoading = true
      getColumns(this.project.id, this.selectedTable.id).then(tableColumns => {
        this.tableColumns = tableColumns
        this.tableColumns.forEach(column => {
          this.mapping.push({
            name: column.name
          })
        })
        this.isLoading = false
      })
    },
  },
  mounted() {
    // fetch projectTables
    this.$store.dispatch("fetchProjectTables", this.project.id)
  },
  methods: {
    mapValueToColumn(value, name) {
      this.mapping.find((map) => map.name === name).value = value
    },
    getModelProperties() {
      this.isLoading = true
      this.$store
          .dispatch("importModel", {
            urn: convertToURN(this.model.objectId),
            model: this.model,
          })
          .then(() => {
            this.isLoading = false
          });
    },
    save() {
      this.$emit("update", {
        table: this.selectedTable.id,
        mapping: this.mapping,
        model: this.model
      });
    },
    closeImportDialog() {
      this.displayImportDialog = false;
    },
    openModelImportDialog() {
      this.displayImportDialog = true;
    },
  },
};
</script>
